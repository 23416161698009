<template>
  <div class="wrapper">
    <div class="card">
      <div class="title">
        <div class="caption">{{ $t('reset_two_factor_auth') }}</div>
      </div>
      <div class="content">
        <div>
          {{ $t('google.reset.enter_backup_code') }}
        </div>
        <div class="mt-3">
          <b-input v-model="form.code" type="text" placeholder="********-****-****-****-************" :class="[msg.status ? 'error' : '']" autocomplete="off" autofocus style="font-family: monospace" @keyup.enter="auth"/>
        </div>
        <div class="msg-box">
          <div v-if="msg.status">
            <i class="owl-status-triangle-warning"></i>
            <span>{{ msg.note }}</span>
          </div>
        </div>
        <div>
          <button :disabled="!status" class="btn btn-solid btn-blue" @click="auth">{{ $t('auth') }}</button>
        </div>
        <div class="mt-4">
          <div class="link link-info">
            <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
          </div>
          <div class="link link-info" style="float: right">
            <div style="font-size: 14px" @click="lost">{{ $t('google.reset.lost_backup_code') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { backupCodeCheck } from '../api/2fa'

export default {
  name: 'verify.google.reset',
  data () {
    return {
      status: false,
      type: 'google',
      form: {
        code: ''
      },
      msg: {
        status: false,
        note: ''
      }
    }
  },
  created () {
    if (this.$route.params.mode === undefined) {
      this.$router.push({
        name: 'verify.index'
      })
    }
  },
  watch: {
    'form.code': function () {
      if (this.form.code.trim() === '') {
        this.status = false
      } else {
        this.status = true
      }
    }
  },
  methods: {
    cancelled () {
      this.$router.push({
        name: 'verify.index'
      })
    },
    lost () {
      this.$router.push({
        name: 'customer.support'
      })
    },
    async auth () {
      this.status = false
      const ret = await backupCodeCheck(this.type, { code: this.form.code })
      if (!ret.status) {
        this.msg.status = true
        this.msg.note = ret.msg
        this.status = true
        return
      }
      this.$router.push({
        name: 'verify.google.reset.scan',
        params: {
          mode: true
        }
      })
    }
  }
}
</script>
